
import { computed, defineComponent, ref, reactive, getCurrentInstance } from "vue";
import { CollectionResourceDoc } from "@/models/jsonapi";
import { Task } from "@/models/tasks";
import { BvTableCtxObject } from "bootstrap-vue";
import { apiDataMorpher, extractRelationshipIds, modelCaseConversion } from "@/utils/apiDataHelper";
import { resourceUrl } from "@/utils/dataExtractors";
import { initializeModal, addAction } from "@/view_scripts/dashboardUtils";

export default defineComponent({
  setup(_) {
    const root = getCurrentInstance().proxy
    const taskTable = ref()

    const totalItems = computed(() => root.$store.state.tasks.pagination.totalItems)

    const getItems = async(ctx: BvTableCtxObject) => {
      const queryParams = {
        'sort': '-due_at',
        'page[number]': ctx.currentPage,
        'page[size]': ctx.perPage,
      }
      await root.$store.dispatch('tasks/load', queryParams)
      return root.$store.state.tasks.data
    }

    const columns = reactive([
      {
        component: () => import("@/components/shared/table/cells/LinkCell.vue"),
        title: "Bezug",
        key: 'relationships.taskable.data.id',
        options: {
          contentFunction: (data: any) => modelCaseConversion(data.item.relationships.taskable.data.type),
          hrefFunction: (data: any) => resourceUrl(data.item.relationships.taskable.data)
        }
      },
      {
        component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        label: "Termin",
        title: "Termin",
        key: 'attributes.dueAt',
        sortable: true,
        options: {type: 'datetime'}
      },
      {
        component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        label: "Termin",
        title: "Termin",
        key: 'attributes.dueAt',
        sortable: true,
        options: {type: 'datetime'}
      },
      {
        component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        label: "Note",
        title: "Note",
        key: 'attributes.note',
      },
      {
        component: () => import("@/components/shared/table/cells/ValueCell.vue"),
        label: "Priorität",
        title: "Priorität",
        key: 'attributes.priority',
      },
      // {
      //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      //   label: "Geschützt",
      //   title: "Geschützt",
      //   key: 'attributes.frozen',
      // },
      // {
      //   component: () => import("@/components/shared/table/cells/ValueCell.vue"),
      //   label: "Data",
      //   title: "Data",
      //   key: 'attributes',
      //   sortable: true,
      // },
    ])

    const update = () => {
      taskTable.value.refreshItems()
    }
    const { modalType, modalProps, addReminderAction, addDestroyTaskAction } = initializeModal(root, update)
    const modalClosed = (reload?: boolean) => {
      modalProps.value = { visible: false }
      modalType.value = undefined
      if (reload) {
        update()
      }
    }
    addReminderAction(columns)
    addDestroyTaskAction(columns)


    // const addDestroyTaskAction = (columns: any) => {
    //   return addAction(columns, {
    //     title: 'Task Löschen',
    //     icon: ['fa', 'trash'],
    //     action: async (data: any) => {
    //       await root.$store.dispatch('tasks/delete', data.item.id)
    //       taskTable.value.refreshItems()
    //     }
    //   })
    // }

    // columns.value = addDestroyTaskAction(columns.value)

    const usersByGroup = [
      {
        groupName: "Innendienst",
        users: [
          {
            name: "Julius",
            id: "1"
          },
          {
            name: "Michael",
            id: "2"
          }
        ]
      },
      {
        groupName: "Admin",
        users: [
          {
            name: "Ekkehard",
            id: "3"
          },
          {
            name: "JB",
            id: "4"
          }
        ]
      }
    ]
    const userOptions = [
      {
        group: "Innendienst"
      },
      {
        name: "Julius",
        id: "1"
      },
      {
        name: "Michael",
        id: "2"
      },
      {
        group: "Admin"
      },
      {
        name: "Ekkehard",
        id: "3"
      },
      {
        name: "JB",
        id: "4"
      }
    ]

    const usersSelected = ref<string[]>([])

    const userGroupsSelected = computed<string[]>(() => {
      let returnedGroups: string[] = []
      const users = usersSelected.value
      usersByGroup.forEach(group => {
        if (group.users.every(user => users.includes(user.id))) {
          returnedGroups.push(group.groupName)
        }
      })
      return returnedGroups
    })

    const groupClicked = (group: string) => {
      const allUserIdsInGroup = usersByGroup.find(element => element.groupName === group)?.users?.map(user => user.id)
      if (!allUserIdsInGroup) return
      // Remove all users of the group if they were all selected
      if (userGroupsSelected.value.includes(group)) {
        usersSelected.value = usersSelected.value.filter( element => !allUserIdsInGroup.includes(element) )
      } else {
        // Else add all users
        const newValues = [...usersSelected.value, ...allUserIdsInGroup]
        usersSelected.value = [...new Set(newValues)]
      }
    }

    const titleActions = []

    return {
      titleActions,
      taskTable,
      getItems,
      totalItems,
      columns,
      usersByGroup,
      userOptions,
      usersSelected,
      userGroupsSelected,
      groupClicked,
      modalType,
      modalClosed,
      modalProps
    }
  }
})
